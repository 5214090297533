/** @format */

import styled from 'styled-components';

type containerProps = {
  ScrollValue: number;
};

export const Container = styled.div<containerProps>`
  width: min(90rem, 95%);
  margin: 1rem auto;
  .community {
    color: ${(props) => props.theme.colors.primary};
  }
   .cusfont {
    font-family: Syncopate;
   }
  .cusfonth {
      font-size: calc(1rem + 1.5vw);
      font-family: Syncopate;
    }
  .ScrollUpArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 4%;
    right: 2%;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 0.4rem;
    padding: 0.52rem;
    opacity: ${(props) => (props.ScrollValue > 15 ? 1 : 0)};
    transition: opacity 0.8s ease-in-out;
  }
  .no_slider {
    display: none;
  }
  .Slider {
    margin-top: 1rem;
    position: relative;
    overflow: hidden;
    filter: drop-shadow(0px 0px 5px rgba(44, 54, 71, 0.2));
    .Sold_logo {
      position: absolute;
      top: 2%;
      left: 2%;
      z-index: 1;
      background-color: black;
      padding: 0.35rem;
      border-radius: 5px;
      p {
        color: white;
      }
    }
    .slider_button {
      position: absolute;
      bottom: 2%;
      right: 3%;
      border-radius: 0.625rem;
      background: #fff;
      background-color: #ffffff;
      color: ${(props) => props.theme.colors.primary};
      padding: 0.63rem;
      cursor: pointer;
      p {
        margin: 0;
      }
    }
    .slick-dots {
      bottom: 8px;
      li {
        button {
          &::before {
            font-size: 10px;
            color: ${(props) => props.theme.colors.primary};
            opacity: 0.3;
          }
        }
        &.slick-active {
          button {
            &::before {
              opacity: 1;
              color: ${(props) => props.theme.colors.primary};
            }
          }
        }
      }
    }
    .slick-prev {
      left: 3%;
      z-index: 100;
      width: 3rem;
      height: 3rem;

      .prev {
        width: 100%;
        height: 100%;
        #card_overlay {
          fill: ${(props) => props.theme.colors.primary}!important;
        }
      }
    }
    .slick-next {
      right: 3%;
      width: 3rem;
      height: 3rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .slick-slide {
      padding: 0 1rem;
      img {
        border-radius: 0.5rem;
        overflow: hidden;
        width: 100%;
        object-fit: cover;
        max-height: 43rem;
      }
    }
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 0.5rem;
  a {
    text-decoration: none;
    color: #6e6e73;
    font-size: clamp(1rem, 1rem + 1vw, 1rem);
  }
  p {
    color: ${(props) => props.theme.colors.primary};
    font-size: clamp(1rem, 1rem + 1vw, 1rem);
    margin: 0;
  }

  @media screen and (max-width: 786px) {
    display: none;
  }
`;

export const Content = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 1rem;
  flex-wrap: wrap;
  & .d-bars {
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
  }
  & .o-bars {
    border-right: 1px solid #d9d9d9;
  }
  .Info {
    text-align: center;
    flex: 1;
    /* padding: 0 8rem; */
    h3 {
      color: ${(props) => props.theme.colors.primary};
      font-family: Inter;
      font-size: 1.5rem;
      font-size: clamp(1rem, 1rem + 1vw, 1.5rem);
      font-weight: 500;
      margin: 0;
    }
    p {
      color: #13131a;
      font-family: Inter;
      font-size: 1rem;
      font-size: clamp(0.75rem, 0.75rem + 1vw, 1rem);
      font-weight: 500;
      margin: 0;
      text-align: center;
    }
    &.first {
      padding-left: 0;
    }
  }
  .Price {
    // margin-left: auto;
    h1 {
      color: ${(props) => props.theme.colors.primary};
      font-family: Inter;
      font-size: 3rem;
      font-size: clamp(1rem, 1rem + 1vw, 2rem);
      font-weight: 600;
    }
  }
  @media screen and (max-width: 825px) {
    /* flex-direction: column; */
    & .d-bars {
      border: none;
    }
    & .o-bars {
      border: none;
    }
  }
`;

export const NavContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  a {
    color: #6e6e73;
    font-family: Inter;
    font-size: 1rem;
    font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
    font-weight: 500;
    text-decoration: none;
    position: relative;
    &:hover::after {
      position: absolute;
      content: '';
      bottom: -20%;
      left: 0;
      width: 100%;
      height: 0.3rem;
      background-color: ${(props) => props.theme.colors.primary};
    }
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const FeaturedContainer = styled.div`
  margin-top: 6rem;
  .FeaturedContainer {
    position: relative;
    .slick-prev {
      top: 110%;
      left: 90%;
      z-index: 100;
    }
    .slick-next {
      top: 110%;
      right: 0;
    }
    .slick-slide {
    }
    .Cards {
      p {
        margin: 0;
      }
    }
  }
`;

export const AppointmentContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  display: block;
  z-index: 10000;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ComparableHomes = styled.div`
  margin: 3rem 0;
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(327px, 1fr));
    gap: 1rem;
  }
`;
export const LoadingContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    border-top: 2px solid ${(props) => props.theme.colors.primary};
  }
`;

export const ImageNotFoundContainer = styled.div`
  background-color: gray;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
  min-height: 45vh;

  .image_box {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .svg_box {
    width: 2rem !important;
    height: 2rem !important;
  }
`;
