/** @format */

import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Container, Content } from './Listing.style';

import { useNavigate } from 'react-router-dom';
import usePermissionSoldProperty from '../../../CustomHooks/usePermissionSoldProperty.customHook';
import PropertyCard, {
  PROPERTY_TYPE_CLASSES,
} from '../../../utilComponent/PropertyCard/PropertyCard.utilComponent';
import SoldHomeForm from '../../../utilComponent/SoldHomeForm/SoldHomeForm.component';
import Dots from './Images/WhiteDots1.svg';
import { FetchProperty } from './Listing.api';
import {
  CustomNextArrow,
  CustomPrevArrow,
  handleNext,
  handlePrevious,
  ListingSettings,
} from './Listing.slider';
import {
  header_text,
  listing_box_redirect,
  property_types_props,
} from './Listing.type';

export default function Listing({ property_type }: property_types_props) {
  const {
    user,
    open,
    handleOpenClose,
    handleNavigation,
    handleSubmitForm,
    handleState,
  } = usePermissionSoldProperty();
  const sliderRef = useRef(null);
  const Navigate = useNavigate();
  const handleNavigateList = () => {
    if (property_type === 'sold' && !user) {
      handleNavigation();
      return;
    }
    Navigate(`../${listing_box_redirect[property_type]}`);
  };
  const [property, setProperty] = useState([]);
  useEffect(() => {
    const FetchData = async () => {
      const Data = await FetchProperty(property_type);
      setProperty(Data);
    };
    FetchData();
  }, []);

  return (
    <Container>
      <img
        src={Dots}
        className="Dots"
        alt="circle"
      />
      <svg
        className="Circle"
        xmlns="http://www.w3.org/2000/svg"
        width="146"
        height="146"
        viewBox="0 0 146 146"
        fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M73 0C32.6832 0 0 32.6832 0 73C0 113.317 32.6832 146 73 146C113.317 146 146 113.317 146 73C146 32.6832 113.317 0 73 0ZM72.9983 20.8574C44.2006 20.8574 20.8555 44.2026 20.8555 73.0003C20.8555 101.798 44.2006 125.143 72.9983 125.143C101.796 125.143 125.141 101.798 125.141 73.0003C125.141 44.2026 101.796 20.8574 72.9983 20.8574Z"
          fill="#FDFDFE"
        />
      </svg>
      <Content>
        <div className="left">
          <h1>{header_text[property_type].header}</h1>
          <p>{header_text[property_type].sub_header}</p>
        </div>
        <div
          className="button"
          onClick={handleNavigateList}>
          <p>See More</p>
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.8125 2.625C4.23234 2.625 3.67594 2.85547 3.2657 3.2657C2.85547 3.67594 2.625 4.23234 2.625 4.8125V14.1875C2.625 15.395 3.605 16.375 4.8125 16.375H14.1875C14.7677 16.375 15.3241 16.1445 15.7343 15.7343C16.1445 15.3241 16.375 14.7677 16.375 14.1875V11.6875C16.375 11.4389 16.4738 11.2004 16.6496 11.0246C16.8254 10.8488 17.0639 10.75 17.3125 10.75C17.5611 10.75 17.7996 10.8488 17.9754 11.0246C18.1512 11.2004 18.25 11.4389 18.25 11.6875V14.1875C18.25 15.2649 17.822 16.2983 17.0601 17.0601C16.2983 17.822 15.2649 18.25 14.1875 18.25H4.8125C3.73506 18.25 2.70175 17.822 1.93988 17.0601C1.17801 16.2983 0.75 15.2649 0.75 14.1875V4.8125C0.75 3.73506 1.17801 2.70175 1.93988 1.93988C2.70175 1.17801 3.73506 0.75 4.8125 0.75H7.3125C7.56114 0.75 7.7996 0.848772 7.97541 1.02459C8.15123 1.2004 8.25 1.43886 8.25 1.6875C8.25 1.93614 8.15123 2.1746 7.97541 2.35041C7.7996 2.52623 7.56114 2.625 7.3125 2.625H4.8125ZM10.125 1.6875C10.125 1.43886 10.2238 1.2004 10.3996 1.02459C10.5754 0.848772 10.8139 0.75 11.0625 0.75H17.3125C17.5611 0.75 17.7996 0.848772 17.9754 1.02459C18.1512 1.2004 18.25 1.43886 18.25 1.6875V7.9375C18.25 8.18614 18.1512 8.4246 17.9754 8.60041C17.7996 8.77623 17.5611 8.875 17.3125 8.875C17.0639 8.875 16.8254 8.77623 16.6496 8.60041C16.4738 8.4246 16.375 8.18614 16.375 7.9375V3.95L11.725 8.6C11.6392 8.69211 11.5357 8.76599 11.4207 8.81723C11.3057 8.86847 11.1815 8.89602 11.0557 8.89824C10.9298 8.90046 10.8047 8.87731 10.688 8.83015C10.5713 8.783 10.4652 8.71282 10.3762 8.6238C10.2872 8.53477 10.217 8.42873 10.1698 8.312C10.1227 8.19526 10.0995 8.07023 10.1018 7.94435C10.104 7.81847 10.1315 7.69433 10.1828 7.57933C10.234 7.46433 10.3079 7.36083 10.4 7.275L15.05 2.625H11.0625C10.8139 2.625 10.5754 2.52623 10.3996 2.35041C10.2238 2.1746 10.125 1.93614 10.125 1.6875Z"
              fill="white"
            />
          </svg>
        </div>
      </Content>
      <div className="Scroll">
        <Slider
          ref={sliderRef}
          prevArrow={
            <CustomPrevArrow onClick={() => handlePrevious(sliderRef)} />
          }
          nextArrow={<CustomNextArrow onClick={() => handleNext(sliderRef)} />}
          {...ListingSettings}
          className="slider_container">
          {property.slice(0, 7).map((data) => (
            <div
              className="scroll_card"
              key={data.id}>
              <PropertyCard
                propertyCardType={PROPERTY_TYPE_CLASSES.propertycard_temp3}
                propertyType={property_type}
                propertyData={data}
                key={data.id}
              />
            </div>
          ))}
        </Slider>
      </div>
      {property_type === 'sold' && open && (
        <SoldHomeForm
          handleOpenClose={handleOpenClose}
          handleState={handleState}
          handleSubmitForm={handleSubmitForm}
        />
      )}
    </Container>
  );
}
