import { CustomArrowProps } from 'react-slick';
import Left from './Images/LeftArrow.svg';
import Right from './Images/RightArrow.svg';
export const CustomPrevArrow = (props: CustomArrowProps) => (
    <img
        src={Left}
        alt="LeftArrow"
        {...props}
        className="custom-prev-arrow"
    />
);

export const CustomNextArrow = (props: CustomArrowProps) => (
    <img
        src={Right}
        alt="RightArrow"
        {...props}
        className="custom-next-arrow"
    />
);

export const handlePrevious = (sliderRef: { current: { slickPrev: () => void; }; }) => {
    sliderRef.current.slickPrev();
};

export const handleNext = (sliderRef: { current: { slickNext: () => void; }; }) => {
    sliderRef.current.slickNext();
};

export const ListingSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1384,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1242,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                // arrows: false,
            },
        },
    ],
};