/** @format */

import axios from 'axios';
import ConvertToCanadianNumber from '../../../../utils/New_construction_details/ConvertNumberToDollar.util';
import {
  propertyProps,
  api_options,
  api_endpoints,
  listing_box_redirect,
  Listing_view,
} from './Listing.type';
import { PropertySetImageBanner } from '../../../../utils/New_construction_details/propertyCardSetImage';
import moment from 'moment';
import { TimeLabel } from '../../../../utils/New_construction_details/timelabel';
const SoldInDays = (listedDay, soldDay): number => {
  if (!listedDay || !soldDay) {
    return null;
  }
  let start = moment(listedDay);
  let end = moment(soldDay);

  return end.diff(start, 'days');
};
/**
 * Fetches property data based on the specified property type.
 * @param property_type - The type of property to fetch
 * @returns An array of property objects with specific details
 * @throws Error if failed to fetch property data
 */
export const FetchProperty = async (property_type: string) => {
  let Properties = [];
  try {
    const fetch = await axios.get(api_endpoints[property_type]);
    let Data = [];
    if (property_type === 'builder_deals') {
      Data = await fetch.data.data.property.data;
    } else {
      Data = await fetch.data.data;
    }
    Properties = Data.map((item: propertyProps) => ({
      id: item[api_options[property_type].id],
      img: coverImage(property_type, item).slice(0, 3),
      price: ConvertToCanadianNumber(item[api_options[property_type].price]),
      title: title_text(property_type, item),
      text: item.realtor,
      bedrooms:
        property_type === 'idx'
          ? item[api_options[property_type].bedrooms]
          : item?.bedrooms === null
          ? 0
          : Number(item?.bedrooms) > Number(item?.additional_bathroom)
          ? Number(item?.bedrooms) - Number(item?.additional_bathroom)
          : item?.bedrooms,
      bathrooms: item[api_options[property_type].bathrooms],
      garage: item[api_options[property_type].garage],
      sale_or_lease:
        property_type === 'idx' || property_type === 'recently_added'
          ? item[api_options[property_type].sale_or_lease]
          : api_options[property_type].sale_or_lease,
      redirect: `../${listing_box_redirect[property_type]}/${Listing_view[property_type]}`,
      parent_property:
        property_type === 'builder_deals' &&
        item.plan_properties[0].property_name,
      company:
        property_type === 'builder_deals' &&
        item.plan_properties[0].builder_name,
      plan: property_type === 'builder_deals' && item.property_name,
      property_sqft: property_type === 'builder_deals' && item.sqft_area,
      listed_price: ConvertToCanadianNumber(item.property_details?.['Lp_dol']),
      sold_on: item?.sold_date?.split(' ')?.[0],
      sold_in: SoldInDays(
        item?.property_details?.Input_date,
        item?.property_details?.Unavail_dt
      ),
      timeLabel: TimeLabel(item.updated_at),
    }));
    return Properties;
  } catch (e) {
    throw new Error('failed to fetch property');
  }
};

/**
 * Generates a title text based on the property type and item details.
 * @param {string} property_type - The type of property.
 * @param {object} item - The item containing address details.
 * @returns {string} - The generated title text.
 */
const title_text = (property_type, item) => {
  switch (property_type) {
    case 'idx':
    case 'sold':
    case 'recently_added':
      return `${item?.address ? item.address + ',' : ''}${
        item?.city ? item.city + ',' : ''
      }${item?.area ? item.area + ',' : ''}${
        item[api_options[property_type]?.zip]
          ? item[api_options[property_type]?.zip] + ','
          : ''
      }${
        item[api_options[property_type]?.country]
          ? item[api_options[property_type]?.country]
          : ''
      }`;
    case 'builder_deals':
      return item.street_address;
  }
};

/**
 * Determines the cover image for a property based on its type.
 * If the property type is 'builder_deals', it sets the cover image using PropertySetImageBanner function.
 * Otherwise, it uses the default photos of the property.
 *
 * @param {string} property_type - The type of the property.
 * @param {object} item - The property object.
 * @returns {string[]} - The cover image for the property.
 */
const coverImage = (property_type, item) => {
  if (property_type === 'builder_deals') {
    return PropertySetImageBanner(item);
  } else {
    return item.photos;
  }
};
