import styled from 'styled-components';

export const FeaturePropertyContainer = styled.div`
  width: min(120rem, 100%);
  margin: 0 auto;
  max-width: 100vw;
  .slider_container {
    overflow: hidden;
    .slick-prev::before,
    .slick-next::before {
      font-size: 40px;
    }
  }
`;
export const PropertyCardSlider = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  .image {
    width: 100%;
    height: calc(100vh - 88px);
    object-fit: cover;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6));
  }
  .property_details {
    position: absolute;
    bottom: 3%;
    left: 2%;
    .heading {
      text-transform: uppercase;
      margin: 0 0 10px;
      font-weight: 500;
      color: white;
    }
    .second_heading {
      color: white;
    }
    .property_info_container {
      display: flex;
      justify-content:start;
      align-items: center;
      gap: 10px;
      .info_property {
        color: white;
      }
    }
  }
  .redirect {
    position: absolute;
    bottom: 3%;
    right: 2%;
    color: white;
    padding: 1rem;
    border: 1px solid white;
  }
`;
