/** @format */

import { ReactNode, createContext, useContext, useReducer } from 'react';

export type mlsDataProps = {
  _id?: string;
  userId?: string;
  mlsNumber: string;
  address: string;
  price: number | string;
  beedroom: string | number;
  bathroom: string | number;
  garage: string | number;
  image: string;
  createdAt?: string;
  updatedAt?: string;
  description: string;
  square_foot: string | number;
};
export type formType = 'add' | 'preview' | null;
type State = {
  previewProperty: mlsDataProps | null;
  property: mlsDataProps[];
  mlsNumber: string | null;
  formVisualState: boolean;
  formType: formType;
};

type Action =
  | {
      type: 'set_property';
      payload: {
        value: mlsDataProps[];
      };
    }
  | { type: 'set_mlsNumber'; payload: { value: string } }
  | {
      type: 'set_formVisualState';
      payload: { value: boolean };
    }
  | {
      type: 'set_formType';
      payload: { value: formType };
    }
  | { type: 'set_previewProperty'; payload: { value: mlsDataProps } };

type ContextValue = {
  state: State;
  setProperty: (value: mlsDataProps[]) => void;
  setMlsNumber: (value: string) => void;
  setFormVisualState: (value: boolean) => void;
  setFormType: (value: formType) => void;
  setPreviewProperty: (value: mlsDataProps) => void;
};

const initialState: State = {
  previewProperty: null,
  property: [],
  mlsNumber: null,
  formVisualState: false,
  formType: null,
};

const DashboardMlsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'set_property':
      return { ...state, property: action.payload.value };
    case 'set_mlsNumber':
      return { ...state, mlsNumber: action.payload.value };
    case 'set_formVisualState':
      return { ...state, formVisualState: action.payload.value };
    case 'set_formType':
      return { ...state, formType: action.payload.value };
    case 'set_previewProperty':
      return { ...state, previewProperty: action.payload.value };
  }
};

const DashboardMlsContext = createContext<ContextValue | undefined>(undefined);

interface DashboardContextProviderProps {
  children: ReactNode;
}
const DashboardMlsContextProvider = ({
  children,
}: DashboardContextProviderProps) => {
  const [state, dispatch] = useReducer(DashboardMlsReducer, initialState);

  const setProperty = (value: mlsDataProps[]) => {
    dispatch({ type: 'set_property', payload: { value } });
  };
  const setMlsNumber = (value: string) => {
    dispatch({ type: 'set_mlsNumber', payload: { value } });
  };
  const setFormVisualState = (value: boolean) => {
    dispatch({ type: 'set_formVisualState', payload: { value } });
  };
  const setFormType = (value: formType) => {
    dispatch({ type: 'set_formType', payload: { value } });
  };
  const setPreviewProperty = (value: mlsDataProps) => {
    dispatch({ type: 'set_previewProperty', payload: { value } });
  };
  const contextValue: ContextValue = {
    state,
    setProperty,
    setMlsNumber,
    setFormVisualState,
    setFormType,
    setPreviewProperty,
  };
  return (
    <DashboardMlsContext.Provider value={contextValue}>
      {children}
    </DashboardMlsContext.Provider>
  );
};

//custom Hook

export function useDashboardMlsContext() {
  const context = useContext(DashboardMlsContext);
  if (!context) {
    throw new Error(
      `DashboardMlsContext must be used in withing a DashBoardMlsContextProvider`
    );
  }
  return context;
}

export default DashboardMlsContextProvider;
