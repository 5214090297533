
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';
import Slider from 'react-slick';
import "./Blogs.style.css"
export default function BlogsTemp1({data}) {
     const [Blogs, setBlog] = useState([]);
     const BlogSliderSettings = {
       arrows: false,
       infinite: true,
       speed: 500,
       slidesToShow: Math.min(Blogs.length, 4),
       slidesToScroll: 1,
       autoplay: true,
       responsive: [
         {
           breakpoint: 1384,
           settings: {
             slidesToShow: Math.min(Blogs.length, 4),
             slidesToScroll: 1,
           },
         },
         {
           breakpoint: 1122,
           settings: {
             slidesToShow: Math.min(Blogs.length, 2),
             slidesToScroll: 1,
           },
         },
         {
           breakpoint: 800,
           settings: {
             slidesToShow: Math.min(Blogs.length, 1),
             slidesToScroll: 1,
           },
         },
       ],
     };
     useEffect(() => {
       const FetchData = async () => {
         const token = Cookies.get('token');
         const agentUrl = window.location.href.split('/')[2];
         //const agentUrl = "pranavjadhav.agentshades.com";
         let config = {
           headers: {
             'x-access-token': token,
           },
           params: {
             domain: agentUrl,
           },
         };
         try {
           const fetchData = await axios.get('/api/get-agent-blog', config);
           const data = await fetchData.data.data;
           setBlog(data);
         } catch (e) {
           throw new Error(e);
         }
       };
       FetchData();
     }, []);
     function stripHTML(html) {
          return html.replace(/<[^>]*>/g, '');
        }
      
        function TruncatedDescription({ card }) {
          const strippedDescription = stripHTML(card.description);
          const truncatedDescription =
            strippedDescription.length > 200
              ? `${strippedDescription.slice(0, 200)}...`
              : strippedDescription;
      
          return (
            <p
              className="card-text"
              style={{ color: 'black', fontFamily: 'Garamond' }}>
              {truncatedDescription}
            </p>
          );
        }
      
  return (
     <div
      className="sec8"
      id="sec5"
      >
        <h2 style={{ color: data.sec8.color }}>{data.sec8.text}</h2>
        <div className="text-center">
          <Slider {...BlogSliderSettings}>
            {Blogs?.map((card: any, index: number) => (
              <div
                key={index}
                className="blog_card"
                onClick={() =>
                  (window.location.href = '/template1/blogview/?id=' + card._id)
                }>
                <div className="card">
                  <img
                    src={'/public/' + card.coverImage}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <p
                      className="card-title"
                      style={{ color: 'black', fontFamily: 'Syncopate' }}>
                      {card.title}
                    </p>
                    <TruncatedDescription card={card} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
  )
}
