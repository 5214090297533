/** @format */

import LiveEdithTextHighLighter from '../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import LiveEditImageHighLighter from '../../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
import {
  FooterContainer,
  FooterSocialMediaLinksContainer,
} from './footer.style';

export default function FooterT3({ data }) {
  return (
    <FooterContainer id="footer">
      <div className="upper_section">
        <div className="leftSection">
          <LiveEditImageHighLighter
            section={'footer'}
            type="img">
            <img
              src={'/public/' + data?.footer?.image}
              alt="logo"
              style={{ width: '100px', height: '100px' }}
            />
          </LiveEditImageHighLighter>
        </div>

        <div className="rightSection">
          {/* <p
          className="footer_mainhead"
          style={{ fontSize: '16px' }}> */}
          <LiveEdithTextHighLighter
            section={'footer'}
            type={'text2'}>
            <div
              className="footer_mainhead"
              dangerouslySetInnerHTML={{
                __html: data?.footer?.text2,
              }}></div>
            {/* {data?.footer?.text2} */}
          </LiveEdithTextHighLighter>
          {/* </p> */}

          {/* <p
          className="footer_subhead"
          style={{ fontSize: '10px', color: 'white' }}> */}
          <LiveEdithTextHighLighter
            section={'footer'}
            type={'text3'}>
            <div
              className="footer_subhead"
              dangerouslySetInnerHTML={{
                __html: data?.footer?.text3,
              }}></div>
            {/* {data?.footer?.text3} */}
          </LiveEdithTextHighLighter>
          {/* </p> */}

          <div className="contact-info">
            {/* <p className="footer_para"> */}
            <LiveEdithTextHighLighter
              section={'footer'}
              type={'text4'}>
              <div
                className="footer_para"
                dangerouslySetInnerHTML={{
                  __html: data?.footer?.text4,
                }}></div>
              {/* {data?.footer?.text4} */}
            </LiveEdithTextHighLighter>
            {/* </p> */}
          </div>

          <div className="contact-info">
            {/* <p className="footer_para"> */}
            <LiveEdithTextHighLighter
              section={'footer'}
              type={'text5'}>
              <div
                className="footer_para"
                dangerouslySetInnerHTML={{
                  __html: data?.footer?.text5,
                }}></div>
              {/* {data?.footer?.text5} */}
            </LiveEdithTextHighLighter>
            {/* </p> */}
          </div>

          <div className="contact-info">
            {/* <p className="footer_para"> */}
            <LiveEdithTextHighLighter
              section={'footer'}
              type={'text6'}>
              <div
                className="footer_para"
                dangerouslySetInnerHTML={{
                  __html: data?.footer?.text6,
                }}></div>
              {/* {data?.footer?.text6} */}
            </LiveEdithTextHighLighter>
            {/* </p> */}
          </div>

          <FooterSocialMediaLinksContainer>
            {data?.footer?.socialmedialinks.map((data, i) => (
              <a
                href={data.link}
                className="image_wrapper"
                key={i}>
                <img
                  src={`/public/${data.image}`}
                  alt="socialMediaLinks"
                />
              </a>
            ))}
          </FooterSocialMediaLinksContainer>
        </div>
      </div>

      <div className="down_section">
        {/* <p
        className="disclaimer"
        style={{ color: 'white', fontFamily: 'Syncopate' }}> */}
        <LiveEdithTextHighLighter
          section={'footer'}
          type={'description'}>
          <div
            className="disclaimer"
            dangerouslySetInnerHTML={{
              __html: data?.footer?.description,
            }}></div>
          {/* {data?.footer?.description} */}
        </LiveEdithTextHighLighter>
        {/* </p> */}
      </div>
    </FooterContainer>
  );
}
