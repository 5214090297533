export type propertyProps = {
  unique_id: Number;
  photos: String[];
  list_price: number;
  address: string;
  county: string;
  zip_code: string;
  realtor: string;
  bedrooms: string;
  bathrooms: string;
  sale_or_lease: string;
  area: string;
  city: string;
  garage_spaces: string;
  property_details: { County: string
    Unavail_dt?:string
    Input_date?:string
   };
  garage: string;
  street_address: string;
  property_name: string;
  sqft_area: string;
  plan_properties: {};
  additional_bathroom:string;
  sold_date?:string
  updated_at?:string
};

export type property_types_props = {
  property_type: "idx" | "sold" | "builder_deals" | "recently_added";
};

export const api_endpoints = {
  idx: "https://valuation.santhoshmathew.com/api/v1/properties/residential?&sale_or_lease=Sale",
  sold: "https://valuation.santhoshmathew.com/api/v1/sold/properties?page=1&sortDir=desc&sortBy=sold_date",
  builder_deals: "https://www.redbuyers.com/api/new-construction",
  recently_added:
    "https://valuation.santhoshmathew.com/api/v1/properties/residential?page=1&perPage=9&sortBy=timestamp_idx&sortDir=desc&sale_or_lease=Sale",
};

export const header_text = {
  idx: {
    header: "Newly Added Homes",
    sub_header: "Check out some of our exclusive listings",
  },
  sold: {
    header: "Sold Houses",
    sub_header: "Check out some of past sales",
  },
  builder_deals: {
    header: "Pre-Construction Hot deals",
    sub_header: "Check out some of our exclusive listings",
  },
  recently_added: {
    header: "Recently Added Homes",
    sub_header: "Check out some of our latest additions",
  },
};

export const api_options = {
  idx: {
    id: "unique_id",
    price: "list_price",
    country: "county",
    garage: "garage_spaces",
    zip: "zip_code",
    bedrooms: "bedrooms",
    bathrooms: "bathrooms",
    sale_or_lease: "sale_or_lease",
  },
  sold: {
    id: "id",
    price: "sold_price",
    country: "county",
    garage: "garage",
    zip: "zip",
    bedrooms: "bedrooms",
    bathrooms: "bathrooms",
    sale_or_lease: "Sold",
  },
  builder_deals: {
    id: "id",
    price: "list_price",
    bedrooms: "bedroom",
    bathrooms: "bathroom",
    garage: "garage",
    sale_or_lease: "New",
    parent_property: "property_name",
    plan: "property_name",
    company: "builder_name",
  },
  recently_added: {
    id: "unique_id",
    price: "list_price",
    country: "county",
    garage: "garage_spaces",
    zip: "zip_code",
    bedrooms: "bedrooms",
    bathrooms: "bathrooms",
    sale_or_lease: "sale_or_lease",
  },
};

export const listing_box_redirect = {
  idx: "idxlisting",
  sold: "soldlisting",
  builder_deals: "builderdeals",
  recently_added: "idxlisting",
};

export const Listing_view = {
  idx: "idxView",
  sold: "soldlisting",
  builder_deals: "builder_view",
  recently_added: "idxlisting",
};
