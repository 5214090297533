/** @format */

import CityCardContainer from '../../../utilComponent/citycards_container/CityCards.utilComponent';
import { CitiesContainer } from './citiescard.style';
// import Left from '../../Images/LeftArrow.svg';
// import Right from './Images/RightArrow.svg';
import { CustomArrowProps } from 'react-slick';
import Left from '../Listing/Images/LeftArrow.svg';
import Right from '../Listing/Images/RightArrow.svg';
export const CustomPrevArrow = (props: CustomArrowProps) => (
  <img
    src={Left}
    alt="LeftArrow"
    {...props}
    className="custom_arrows_prev"
  />
);

export const CustomNextArrow = (props: CustomArrowProps) => (
  <img
    src={Right}
    alt="RightArrow"
    {...props}
    className="custom_arrows_next"
  />
);
export default function CitiesCard({ data }) {
  return (
    <CitiesContainer id="sec4">
      <h1 className="cities_header">{data.text}</h1>
      <CityCardContainer
        data={data?.cards}
        CustomArrowsNext={CustomNextArrow}
        CustomArrowsPrev={CustomPrevArrow}
        type={'sec10'}
      />
    </CitiesContainer>
  );
}
