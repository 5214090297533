/** @format */

import React from 'react';
import './Footer.css';
import LiveEdithTextHighLighter from '../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import LiveEditImageHighLighter from '../../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
export default function Footer({ footerData }) {
  return (
    <div
      className="footer"
      id="footer">
      <hr className="footer-divider" />
      <div className="container-xl footer-container">
        <div className="footer-left">
          <LiveEditImageHighLighter
            section={'footer'}
            type="img">
            <img
              src={'/public/' + footerData.image}
              alt="logo"
              className="footer-logo"
            />
          </LiveEditImageHighLighter>
        </div>
        <div className="footer-right">
          {/* <p className="footer-text large"> */}
          <LiveEdithTextHighLighter
            section="footer"
            type="text2">
            <div
              className="footer_mainhead"
              dangerouslySetInnerHTML={{
                __html: footerData?.text2,
              }}></div>
            {/* {footerData.text2} */}
          </LiveEdithTextHighLighter>
          {/* </p> */}
          {/* <p
            className="footer-text small"
            style={{ fontSize: '12px', color: 'white' }}> */}
          <LiveEdithTextHighLighter
            section="footer"
            type="text3">
            <div
              className="footer_subhead"
              dangerouslySetInnerHTML={{
                __html: footerData?.text3,
              }}></div>
            {/* {footerData.text3} */}
          </LiveEdithTextHighLighter>
          {/* </p> */}
          {/* <h6
            className="footer-heading"
            style={{ fontSize: '12px', color: 'white' }}>
            Phone
          </h6> */}
          <p
            className="footer-text footer-phone"
            style={{ fontSize: '12px', color: 'white' }}>
            <LiveEdithTextHighLighter
              section="footer"
              type="text4">
              <div
                className="footer_para"
                dangerouslySetInnerHTML={{
                  __html: footerData?.text4,
                }}></div>
              {/* {footerData.text4} */}
            </LiveEdithTextHighLighter>
          </p>
          {/* <h6
            className="footer-heading"
            style={{ fontSize: '12px', color: 'white' }}>
            Email
          </h6> */}
          <p
            className="footer-text footer-email"
            style={{ fontSize: '12px', color: 'white' }}>
            <LiveEdithTextHighLighter
              section="footer"
              type="text5">
              <div
                className="footer_para"
                dangerouslySetInnerHTML={{
                  __html: footerData?.text5,
                }}></div>
              {/* {footerData.text5} */}
            </LiveEdithTextHighLighter>
          </p>
          {/* <h6
            className="footer-heading"
            style={{ fontSize: '12px', color: 'white' }}>
            Address
          </h6> */}
          <p
            className="footer-text footer-address"
            style={{ fontSize: '12px', color: 'white' }}>
            <LiveEdithTextHighLighter
              section="footer"
              type="text6">
              <div
                className="footer_para"
                dangerouslySetInnerHTML={{
                  __html: footerData?.text6,
                }}></div>
              {/* {footerData.text6} */}
            </LiveEdithTextHighLighter>
          </p>
          <div className="footer-social">
            {footerData.socialmedialinks.map((data, i) => (
              <a
                href={data.link}
                className="image_wrapper"
                key={i}>
                <img
                  src={`/public/${data.image}`}
                  alt="socialMediaLinks"
                />
              </a>
            ))}
          </div>
        </div>
      </div>
      <hr className="footer-divider" />
      <div className="footer-bottom-wrapper">
        <p
          className="footer_bottom_text"
          style={{ color: 'white' }}>
          <LiveEdithTextHighLighter
            section="footer"
            type="description">
            <div
              className="disclaimer"
              dangerouslySetInnerHTML={{
                __html: footerData?.description,
              }}></div>
            {/* {footerData.description} */}
          </LiveEdithTextHighLighter>
        </p>
      </div>
    </div>
  );
}
