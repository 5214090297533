/** @format */

import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const FooterContainer = styled.div`
  width: min(120rem, 100%);
  margin: 0 auto;
  margin-top: 4rem;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;

  .upper_section {
    display: flex;
    justify-content: space-between;
    padding-block: clamp(1rem, 2vw, 2rem);
    padding-inline: clamp(1rem, 4vw, 4rem);
    flex-wrap: wrap;

    .leftSection {
      flex: 0 0 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1rem;
      padding-left: 2rem; 

      .description {
        font-size: 0.9rem;
        margin-top: 0.5rem;
        word-break: break-word;
        color: white;
        text-align: left;
      }

      img {
        margin-bottom: 1rem;
        width: 100px;
        height: 100px;
      }
    }

    .rightSection {
      flex: 0 0 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      gap: 1rem;

      .footer_mainhead {
        font-size: 1.5rem;
        font-family: Syncopate;
        color: white;
        margin: 0;
        line-height: 1.2;
      }

      .footer_subhead {
        font-size: 1.1rem;
        font-family: Syncopate;
        color: white;
        margin: 0;
        line-height: 1.2;
      }

      .footer_para {
        font-size: 0.7rem;
        margin: 0;
        color: white;
        font-family: Syncopate;
        line-height: 1.1;
      }

      hr {
        margin: 0.5rem 0;
        border: none;
        border-top: 1px solid white;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center; 

      .leftSection,
      .rightSection {
        justify-content: center;
        align-items: center; 
        text-align: center;
        flex: none;
        width: 100%; 
        margin-bottom: 2rem; 
      }

      .rightSection {
        margin-top: 0; 
      }
    }
  }

  .down_section {
    font-family: 'Syncopate', sans-serif;
 text-align: center; 
  font-size: 0.5rem; 
    .disclaimer {
      font-size: 0.5rem;
      font-family: Syncopate;
      color: white;
      line-height: 1.1;
      margin: 0.5rem 0;
    }
    border-top: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 1rem;

    .footer_mark {
      font-family: Inter;
      font-size: 1rem;
      font-weight: 400;
      font-family: Syncopate;
      line-height: 1.3331rem;
      color: white;
    }

    @media screen and (max-width: 532px) {
      justify-content: center;
    }
  }
`;

export const FooterSocialMediaLinksContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  .image_wrapper {
    margin: 0.5rem;
  }
`;
